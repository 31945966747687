import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pk-formation',
  templateUrl: './pk-formation.component.html',
  styleUrls: ['./pk-formation.component.css']
})
export class PkFormationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
