
<div class="home" id="home">
    <!-- background-image: url('https://mdbootstrap.com/img/Photos/Others/images/76.webp'); -->
   <header>
     <!-- Navbar -->
     <nav class="navbar navbar-expand-lg navbar-light  fixed-top bg-red mb-2">
       <div class="container-fluid">
         <!--<button
           class="navbar-toggler"
           type="button"
           data-mdb-toggle="collapse"
           data-mdb-target="#navbarExample01"
           aria-controls="navbarExample01"
           aria-expanded="false"
           aria-label="Toggle navigation"
         >
           <i class="fas fa-bars"></i>
         </button>
         <div class="collapse navbar-collapse justify-content-center text-uppercase" id="navbarExample01">
           <ul class="navbar-nav  mb-2 mb-lg-0">
             <li class="nav-item">
               <a class="nav-link {{activeHome ? 'active-link' : ''}}" aria-current="page" href="#home" (click)="activeLink(enumMenuLink.HOME)">Acceuil</a>
             </li>
             &lt;!&ndash; <li class="nav-item">
               <a class="nav-link" href="#a-propos">à propos</a>
             </li> &ndash;&gt;
             <li class="nav-item">
               <a class="nav-link {{activeExperience ? 'active-link' : ''}}" href="#experience" (click)="activeLink(enumMenuLink.EXPERIENCES)">Expériences</a>
             </li>
             <li class="nav-item">
               <a class="nav-link {{activeProject ? 'active-link' : ''}}" href="#project" (click)="activeLink(enumMenuLink.PROJECTS)">Projets</a>
             </li>
             <li class="nav-item">
               <a class="nav-link {{activeSkill ? 'active-link' : ''}}" href="#skill" (click)="activeLink(enumMenuLink.SKILLS)">Compétences</a>
             </li>
             <li class="nav-item">
               <a class="nav-link {{activeFormation ? 'active-link' : ''}}" href="#formation" (click)="activeLink(enumMenuLink.FORMATIONS)">Formations</a>
             </li>
           </ul>
         </div>-->
       </div>
     </nav>
     <!-- Navbar -->

     <!-- Background image -->
     <div class="p-5 text-center" >
       <div class="mask" >
         <div class="d-flex justify-content-center align-items-center h-100">
           <div class="text-white">
             <img width="175" height="175"  class="mt-2 rounded-circle img-color" src="../assets/photo_cv-removebg-preview.png" alt="Photo de Pascal">
             <br>
             <br>
             <h1 class="display-1">Pascal KABORE</h1>
             <div class="mt-4">
              <ngx-typed-js
               [strings]="[
                          toFrontUpperCase('Responsable TIC / ICT Manager'), 
                          toFrontUpperCase('Ingénieur Système d\'Information / Information Systems Engineer'), 
                          toFrontUpperCase('Développeur Full Stack / Full Stack Developer'), 
                          toFrontUpperCase('Consultant.')
                        ]"
               [shuffle]="true"
               [typeSpeed]="40"
               [backSpeed]="7"
               [loop]="true"
               [fadeOut]="false"
               >
               <hr>
               <h2 class="typing"></h2>
               <hr>
             </ngx-typed-js>
             </div>
              

             <br>
             <br>
             <div >
                  <a href="https://www.linkedin.com/in/pascal-kabore-561872183/" title="Compte linkedin" target="_blank" class="btn btn-outline-primary btn-lg mr-2 mb-2"> <i class="fab fa-linkedin fa-2x"></i></a>
                <a href="https://github.com/Pascob" target="_blank" class="btn btn-outline-dark btn-lg mr-2 mb-2" title="Compte github"><i class="fab fa-github fa-2x"></i></a>
                <a  class="btn btn-outline-warning btn-lg mr-2 mb-2" target="_blank" href="https://gitlab.com/Pascob" title="Compte gitlab"><i class="fab fa-gitlab fa-2x"></i></a>
                <a href="https://www.facebook.com/pascal.pascob" target="_blank" class="btn btn-outline-primary btn-lg mb-2" title="Compte facebook"><i class="fab fa-facebook fa-2x"></i></a>
             </div>
             <br><br><br>
             <a class="btn btn-outline-warning btn-lg mr-2 mb-2" href="mailto:pascob.pk@gmail.com" role="button">
               <i class="fas fa-envelope mr-1"></i> 
               Me contacter
             </a>
              <a class="btn btn-outline-warning btn-lg mb-2" target="_blank" href="{{urlToCV}}" role="button">
               <i class="fas fa-download mr-1"></i>
               Télécharger CV
             </a>
           </div>
         </div>
       </div>
     </div>
   </header>
  <div id="particles-js"></div>

 </div>
