<div class="container">
  <div class="row">
    <div class="col-md-4 mt-2">
      <mat-card class="bg-info img-color">
        <mat-card-header >
          <img mat-card-avatar class="img-fluid rounded-circle" src="../../../assets/inovafrique.jpg" alt="Logo Begotech Burkina">
          <mat-card-title >
            SIM CNSS - DGA
          </mat-card-title>
          <mat-card-subtitle >
            A2SYS CONSULTING
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
<!--          Mise en place d’une application de la Direction de la Gestion des Assurés (DGA) et d’un tableau de bord de la Direction Générale de la CNSS Niger-->
        </mat-card-content>
        <mat-card-actions>
<!--          <mat-chip-list>-->
<!--            <mat-chip >Analyse</mat-chip>-->
<!--            <mat-chip>Conception</mat-chip>-->
<!--            <mat-chip>Développement</mat-chip>-->
<!--          </mat-chip-list>-->
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-md-4 mt-2">
      <mat-card class="bg-info img-color">
        <mat-card-header >
          <img mat-card-avatar class="img-fluid rounded-circle" src="../../../assets/inovafrique.jpg" alt="Logo Begotech Burkina">
          <mat-card-title >
            SIGFF
          </mat-card-title>
          <mat-card-subtitle >
            BEGOTECH BURKINA
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
<!--          Mise en place d’une application de la Direction de la Gestion des Assurés (DGA) et d’un tableau de bord de la Direction Générale de la CNSS Niger-->
        </mat-card-content>
        <mat-card-actions>
<!--          <mat-chip-list>-->
<!--            <mat-chip >Analyse</mat-chip>-->
<!--            <mat-chip>Conception</mat-chip>-->
<!--            <mat-chip>Développement</mat-chip>-->
<!--          </mat-chip-list>-->
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-md-4 mt-2">
      <mat-card class="bg-info img-color">
        <mat-card-header >
          <img mat-card-avatar class="img-fluid rounded-circle" src="../../../assets/inovafrique.jpg" alt="Logo Begotech Burkina">
          <mat-card-title >
            FONERNET
          </mat-card-title>
          <mat-card-subtitle >
            BEGOTECH BURKINA
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
<!--          Mise en place d’une application de la Direction de la Gestion des Assurés (DGA) et d’un tableau de bord de la Direction Générale de la CNSS Niger-->
        </mat-card-content>
        <mat-card-actions>
<!--          <mat-chip-list>-->
<!--            <mat-chip >Analyse</mat-chip>-->
<!--            <mat-chip>Conception</mat-chip>-->
<!--            <mat-chip>Développement</mat-chip>-->
<!--          </mat-chip-list>-->
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-md-4 mt-4">
      <mat-card class="bg-info img-color">
        <mat-card-header >
          <img mat-card-avatar class="img-fluid rounded-circle" src="../../../assets/inovafrique.jpg" alt="Logo Begotech Burkina">
          <mat-card-title >
            BT-INTERVENTION
          </mat-card-title>
          <mat-card-subtitle >
            BEGOTECH BURKINA
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
<!--          Mise en place d’une application de la Direction de la Gestion des Assurés (DGA) et d’un tableau de bord de la Direction Générale de la CNSS Niger-->
        </mat-card-content>
        <mat-card-actions>
<!--          <mat-chip-list>-->
<!--            <mat-chip >Analyse</mat-chip>-->
<!--            <mat-chip>Conception</mat-chip>-->
<!--            <mat-chip>Développement</mat-chip>-->
<!--          </mat-chip-list>-->
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-md-4 mt-4">
      <mat-card class="bg-info img-color">
        <mat-card-header >
          <img mat-card-avatar class="img-fluid rounded-circle" src="../../../assets/inovafrique.jpg" alt="Logo Begotech Burkina">
          <mat-card-title >
            BT-SOUTENANCE
          </mat-card-title>
          <mat-card-subtitle >
            BEGOTECH BURKINA
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
<!--          Mise en place d’une application de la Direction de la Gestion des Assurés (DGA) et d’un tableau de bord de la Direction Générale de la CNSS Niger-->
        </mat-card-content>
        <mat-card-actions>
<!--          <mat-chip-list>-->
<!--            <mat-chip >Analyse</mat-chip>-->
<!--            <mat-chip>Conception</mat-chip>-->
<!--            <mat-chip>Développement</mat-chip>-->
<!--          </mat-chip-list>-->
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-md-4 mt-4">
      <mat-card class="bg-info img-color">
        <mat-card-header >
          <img mat-card-avatar class="img-fluid rounded-circle" src="../../../assets/begotech.png" alt="Logo Begotech Burkina">
          <mat-card-title >
            BT-SMS
          </mat-card-title>
          <mat-card-subtitle >
            BEGOTECH BURKINA
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
<!--          Mise en place d’une application de la Direction de la Gestion des Assurés (DGA) et d’un tableau de bord de la Direction Générale de la CNSS Niger-->
        </mat-card-content>
        <mat-card-actions>
<!--          <mat-chip-list>-->
<!--            <mat-chip >Analyse</mat-chip>-->
<!--            <mat-chip>Conception</mat-chip>-->
<!--            <mat-chip>Développement</mat-chip>-->
<!--          </mat-chip-list>-->
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-md-4 mt-4">
      <mat-card class="bg-info img-color">
        <mat-card-header >
          <img mat-card-avatar class="img-fluid rounded-circle" src="../../../assets/begotech.png" alt="Logo Begotech Burkina">
          <mat-card-title >
            INOVA STOCK
          </mat-card-title>
          <mat-card-subtitle >
            INOVAFRIQUE
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
<!--          Mise en place d’une application de la Direction de la Gestion des Assurés (DGA) et d’un tableau de bord de la Direction Générale de la CNSS Niger-->
        </mat-card-content>
        <mat-card-actions>
<!--          <mat-chip-list>-->
<!--            <mat-chip >Analyse</mat-chip>-->
<!--            <mat-chip>Conception</mat-chip>-->
<!--            <mat-chip>Développement</mat-chip>-->
<!--          </mat-chip-list>-->
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-md-4 mt-4">
      <mat-card class="bg-info img-color">
        <mat-card-header >
          <img mat-card-avatar class="img-fluid rounded-circle" src="../../../assets/begotech.png" alt="Logo Begotech Burkina">
          <mat-card-title >
            TRACE LOGICIELLE
          </mat-card-title>
          <mat-card-subtitle >
            TRACE TRANSIT
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
<!--          Mise en place d’une application de la Direction de la Gestion des Assurés (DGA) et d’un tableau de bord de la Direction Générale de la CNSS Niger-->
        </mat-card-content>
        <mat-card-actions>
<!--          <mat-chip-list>-->
<!--            <mat-chip >Analyse</mat-chip>-->
<!--            <mat-chip>Conception</mat-chip>-->
<!--            <mat-chip>Développement</mat-chip>-->
<!--          </mat-chip-list>-->
        </mat-card-actions>
      </mat-card>
    </div>

  </div>
</div>
