<!--

<hr>

<div class="container">
  <h4>Timeline Style : Demo-5</h4>
  <div class="row">
    <div class="col-md-12">
      <div class="main-timeline5">
        <div class="timeline">
          <div class="timeline-icon">
            <img width="150" class="img-fluid rounded-circle zoom img-color" src="../../../assets/a2sys.jpg" alt="Logo A2sys">
          </div>
          <div class="timeline-content">
            <h3 class="title">Web Desginer</h3>
            <p class="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia mi ultrices, luctus nunc ut, commodo enim. Vivamus sem erat.
            </p>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-icon">
            <img width="100" class="img-fluid rounded-circle zoom img-color" src="../../../assets/begotech.png" alt="Logo Begotech Burkina">
          </div>
          <div class="timeline-content">
            <h3 class="title">Web Developer</h3>
            <p class="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia mi ultrices, luctus nunc ut, commodo enim. Vivamus sem erat.
            </p>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-icon"><span class="year">2016</span></div>
          <div class="timeline-content">
            <h3 class="title">Web Desginer</h3>
            <p class="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia mi ultrices, luctus nunc ut, commodo enim. Vivamus sem erat.
            </p>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-icon"><span class="year">2015</span></div>
          <div class="timeline-content">
            <h3 class="title">Web Developer</h3>
            <p class="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia mi ultrices, luctus nunc ut, commodo enim. Vivamus sem erat.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>

<div class="demo">
  <div class="container">
    <h4>Timeline Style : Demo-6</h4>
    <div class="row">
      <div class="col-md-12">
        <div class="main-timeline6">
          <div class="timeline">
            <div class="timeline-content">
              <span class="year">2018</span>
              <div class="content-inner">
                <span class="icon"><i class="fa fa-globe"></i></span>
                <h3 class="title">Web Designer</h3>
                <p class="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer malesuada tellus lorem, et condimentum neque commodo quis. Quisque eu ornare risus, vitae fermentum eros. Etiam venenatis ac.
                </p>
              </div>
            </div>
          </div>
          <div class="timeline">
            <div class="timeline-content">
              <span class="year">2017</span>
              <div class="content-inner">
                <span class="icon"><i class="fa fa-rocket"></i></span>
                <h3 class="title">Web Developer</h3>
                <p class="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer malesuada tellus lorem, et condimentum neque commodo quis. Quisque eu ornare risus, vitae fermentum eros. Etiam venenatis ac.
                </p>
              </div>
            </div>
          </div>
          <div class="timeline">
            <div class="timeline-content">
              <span class="year">2016</span>
              <div class="content-inner">
                <span class="icon"><i class="fa fa-briefcase"></i></span>
                <h3 class="title">Web Designer</h3>
                <p class="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer malesuada tellus lorem, et condimentum neque commodo quis. Quisque eu ornare risus, vitae fermentum eros. Etiam venenatis ac.
                </p>
              </div>
            </div>
          </div>
          <div class="timeline">
            <div class="timeline-content">
              <span class="year">2015</span>
              <div class="content-inner">
                <span class="icon"><i class="fa fa-mobile"></i></span>
                <h3 class="title">Web Developer</h3>
                <p class="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer malesuada tellus lorem, et condimentum neque commodo quis. Quisque eu ornare risus, vitae fermentum eros. Etiam venenatis ac.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
<!--
<div class="timeline">
  <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
    <div class="col-10 col-md-5 order-3 order-md-1 timeline-content">
      <h3 class=" text-light">A2SYS Consulting</h3>
      <div class="ml-3">
        <h4>Développeur full stack</h4>
        <mat-chip-list>
          <mat-chip >Analyse</mat-chip>
          <mat-chip>Conception</mat-chip>
          <mat-chip>Développement</mat-chip>
        </mat-chip-list>
        <br>
      </div>    </div>
    <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
    </div>
    <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
      <time>DEPUIS JUIN 2021</time>
    </div>
  </div>
  <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
    <div class="col-10 col-md-5 order-3 order-md-1 timeline-content">
      <h3 class="text-light"></h3>
      <div class="ml-3">
        <h4>Développeur full stack</h4>
        <mat-chip-list>
          <mat-chip >Analyse</mat-chip>
          <mat-chip>Conception</mat-chip>
          <mat-chip>Développement</mat-chip>
        </mat-chip-list>
        <br>
      </div>
    </div>
    <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
    </div>
    <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
      <time>JUIN 2019 - MARS 2021</time>
    </div>
  </div>
  <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
    <div class="col-10 col-md-5 order-3 order-md-1 timeline-content">
      <h3 class=" text-light">IventIt Africa</h3>
      <div class="ml-3">
        <h4>Développeur full stack</h4>
        <mat-chip-list>
          <mat-chip >Analyse</mat-chip>
          <mat-chip>Conception</mat-chip>
          <mat-chip>Développement</mat-chip>
        </mat-chip-list>
        <br>
      </div>    </div>
    <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
    </div>
    <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
      <time>AVRIL - JUILLET 2019</time>
    </div>
  </div>
  <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
    <div class="col-10 col-md-5 order-3 order-md-1 timeline-content">
      <h3 class=" text-light">Inovafrique</h3>
      <div class="ml-3">
        <h4>Développeur full stack</h4>
        <mat-chip-list>
          <mat-chip >Analyse</mat-chip>
          <mat-chip>Conception</mat-chip>
          <mat-chip>Développement</mat-chip>
        </mat-chip-list>
        <br>
      </div>    </div>
    <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
      <img width="70" class="img-fluid rounded-circle zoom img-color" src="../../../assets/inovafrique.jpg" alt="Logo Begotech Burkina">
    </div>
    <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
      <time>AOÛT - DÉCEMBRE 2018</time>
    </div>
  </div>
</div>
-->

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="main-timeline2">
        <div class="timeline">
          <span class="icon">
            <img width="70" class="img-fluid rounded-circle" src="../../../assets/a2sys.jpg" alt="Logo A2sys">
          </span>
          <div class="timeline-content">
            <h3 class="title">A2SYS CONSULTING</h3>

            <div class="description">
              <p class="h6">Développeur full stack</p>
              <p>DEPUIS JUIN 2021</p>

              <p >
                <mat-chip-list>
                  <mat-chip >Analyse</mat-chip>
                  <mat-chip>Conception</mat-chip>
                  <mat-chip>Développement</mat-chip>
                </mat-chip-list>
              </p>
            </div>
          </div>
        </div>

        <div class="timeline">
          <span class="icon">
            <img width="70" class="img-fluid rounded-circle zoom img-color" src="../../../assets/begotech.png" alt="Logo Begotech Burkina">
          </span>
          <div class="timeline-content">
            <h3 class="title">Begotech Burkina SARL</h3>
            <div class="description">
              <p class="h6">Développeur full stack</p>
              <p>JUIN 2019 - MARS 2021</p>

              <p >
                <mat-chip-list>
                  <mat-chip >Analyse</mat-chip>
                  <mat-chip>Conception</mat-chip>
                  <mat-chip>Développement</mat-chip>
                </mat-chip-list>
              </p>
            </div>
          </div>
        </div>

        <div class="timeline">
          <span class="icon fa fa-university"></span>
          <div class="timeline-content">
            <h3 class="title">IVENTIT AFRICA</h3>
            <div class="description">
              <p class="h6">Développeur full stack</p>
              <p>AOÛT - DÉCEMBRE 2018</p>

              <p >
                <mat-chip-list>
                  <mat-chip >Analyse</mat-chip>
                  <mat-chip>Conception</mat-chip>
                  <mat-chip>Développement</mat-chip>
                </mat-chip-list>
              </p>
            </div>
          </div>
        </div>

        <div class="timeline">
          <span class="icon">
            <img width="70" class="img-fluid rounded-circle zoom img-color" src="../../../assets/inovafrique.jpg" alt="Logo INOVAFRIQUE">
          </span>
          <div class="timeline-content">
            <h3 class="title">INOVAFRIQUE</h3>
            <div class="description">
              <p class="h6">Développeur full stack</p>
              <p>AOÛT - DÉCEMBRE 2018</p>

              <p >
                <mat-chip-list>
                  <mat-chip >Analyse</mat-chip>
                  <mat-chip>Conception</mat-chip>
                  <mat-chip>Développement</mat-chip>
                </mat-chip-list>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
