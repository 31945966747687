<!-- HEADER -->
<app-header></app-header>
<!--

<div id="experience" class="page">
  <h1 class="text-center text-muted">EXPÉRIENCES</h1>
  <app-pk-experiences></app-pk-experiences>
</div>

<div id="project" class="page">
  <h1 class="text-center text-muted">PROJETS</h1>
  <app-pk-project></app-pk-project>
</div>

<div id="skill" class="page">
  <h1 class="text-center text-muted">Compétences</h1>
  <app-pk-skills></app-pk-skills>
&lt;!&ndash;  <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>&ndash;&gt;

</div>

<div id="formation" class="page">
  <h1 class="text-center text-muted">Formations</h1>
  <app-pk-formation></app-pk-formation>
</div>
-->
